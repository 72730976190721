import React, { useState, useEffect } from 'react';
import { BrowserRouter } from 'react-router-dom';

import Header from './components/Header';

import GlobalStyle from './assests/styles/global';
import Routes from './routes';

// import { Container } from './styles';



const App: React.FC = () => {

  const [bgHeader, setBgHeader] = useState(false);

  useEffect(() => {
    function scrollListener()  {
      if(window.scrollY > 30){
        setBgHeader(true);
      }else {
        setBgHeader(false);
      }
    }

    window.addEventListener('scroll', scrollListener);

    return () => window.removeEventListener('scroll', scrollListener);

  }, []);

  // console.log('sss => ',bgHeader);

  return(
    <>
      <BrowserRouter>
        <Header colorHeader={bgHeader}></Header>
        <Routes />
      </BrowserRouter>
      <GlobalStyle />
    </>
  );
}

export default App;