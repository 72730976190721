import styled from  'styled-components';

import backgroundParallax from '../../assests/images/sobre.jpg';

export const SpaceContent = styled.div`
  width: 100%;
  position: relative;
  z-index: 3;
  padding-top: 700px;
`;

export const ContentFluid = styled.div`
  width: 100%;
  position: relative;
  background-color:#FFF;
`;

export const Content = styled.div`
  max-width: 1120px;  
  margin: 0 auto;
`;

export const BannerSite = styled.div`
  width: 100%;
  max-height: 80vh;
  position: fixed;
  z-index: 0;
  > img {
    width: 100%;  
    object-fit: cover;
    overflow: hidden;
    max-height: 800px;
    min-height: 800px;
    object-position: center;
  }
`;

export const LinhaItem = styled.ul`
  display: flex;
  justify-content:  space-around;
  bottom: 100px;
  position: relative;
  list-style: none;

  flex-wrap: wrap;

  > li {
    text-align: center;
    cursor: pointer;
    width: 100%;
    margin-bottom: 40px;    

    @media (min-width: 1000px) {
      width: 195px;
      flex-direction: row;
      margin-bottom: 0px;
    }
  }

  > li img{
    box-shadow: 0 1px 3px rgba(0, 0, 0, 0.5);
    border-radius: 100%;
    border: 2px solid rgba(255, 255, 255, 1);
    background-color: rgba(255, 255, 255, 1);
    overflow: hidden;
    height: 190px;
    width: 190px;
    margin-bottom: 10px;
    margin: 0 auto;
  }

  > li strong{
    color: #3B3A3A;
    font: 700 16px Roboto;
    transition: color 0.2s;
    margin-top: 10px;
    &:hover {
      color: #EDCD1F;
    }
  }
  a {
    text-decoration: none;
    display: flex;
    flex-direction: column; 
  }
`;

export const TitleSection = styled.h1`
  color: #C6C6C6;
  font: 700 5.5rem Roboto;
  letter-spacing: 0.05em;
  text-align: center;
  margin-bottom: 50px;
`; 

export const TitleSectionLeft = styled.h1`
  color: #C6C6C6;
  font: 700 5.5rem Roboto;
  letter-spacing: 0.05em;
  text-align: center;
  margin-bottom: 50px;

  @media (min-width: 900px) {
    text-align: left;
  }

`; 

export const BorderTitle = styled.div`
  border-top: 10px solid rgba(226, 28, 33, 1);
  width: 118px;
  height: 10px;
  margin: 0 auto;
  margin-top: 8px;
`;

export const BorderLeft = styled.div`
  border-top: 10px solid rgba(226, 28, 33, 1);
  width: 118px;
  height: 10px;
  margin-top: 8px;
  margin: 0 auto;

  @media (min-width: 900px) {
    margin: auto 0;
  }


`;

export const TextSection = styled.p`
  font-family: 'Helvetica';
  color: #3B3A3A;
  font-size: 18px;
  text-align: center;
  margin-bottom: 60px;
`;

export const BackgroundAbout = styled.div`
  position: relative;
`;

export const BackgroundAboutFixed = styled.div`
  background-image: url(${backgroundParallax});
  background-attachment: fixed;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  /* height: 700px; */
`;

export const SectionAbout = styled.div`
  background: transparent;
  
`;

export const TextAbout = styled.div`

  padding-top: 70px;
  padding-bottom: 50px;
  background-color: #F7F7F7;
  width: 100%;
  display: flex;
  align-items: center;
  flex-direction: column;

  @media (min-width: 900px) {
    width: 60%;
    align-items: flex-end;
    padding-right: 50px;
  }

  > p {
    width: 80%;
    font-family: 'Helvetica';
    color: #3B3A3A;
    font-size: 16px;
    line-height: 1.3em;
    text-align: justify;

    @media (min-width: 900px) {
      width: 50%;
    }
  }
`;

export const TitleAbout = styled.div`
  color: #8B0000;
  font: 700 1.8rem Roboto;
  margin: 40px 0 5px 0;
  letter-spacing: 0.03em;
`;

export const FaixaAbout = styled.div`
  background-color: rgba(0, 0, 0, 0.53);
  height: 25px;
`;

export const SectionClient = styled.div`
  width: 100%;
  padding: 80px 0;
`;

export const ListClient = styled.div`
  max-width: 1120px;  
  margin: 0 auto;
  display: flex;
  justify-content: center;
  align-items: center;

  flex-direction: column;

  @media (min-width: 1000px) {
    flex-direction: row;
    justify-content: space-around;
  }

`;

export const ItemClient = styled.div`
  width: 150px;
  margin-bottom: 60px;
  
  a {
    display: block;
  }

  @media (min-width: 1000px) {
    margin-bottom: 0;
  }
`;

export const MapaContato = styled.div`
  margin: 80px auto 0 auto;
  text-align: center;
  width: 100%;

  div iframe {
    width: 100%;
    height: 365px;
    border: 0;
    margin-top: 20px;

    @media (min-width: 1000px) {
     width: 940px;
    }

  }

  img {
    width: 100%;
  }

  @media (min-width: 1000px) {
    width: 940px;
  }
`;

export const ContentContato = styled.div`
  display: flex;
  max-width: 1000px;  
  margin: 0px auto;
  padding: 80px 0;

  flex-direction: column;
  justify-content: center;
  align-items: center;

  @media (min-width: 1000px) {
    justify-content: space-between;
    flex-direction: row;
    align-items: start;
  }

`;

export const FormContato = styled.div`
  width: 80%;
  margin-bottom: 80px;

  @media (min-width: 1000px) {
    width: 40%;
  }

  > strong {
    font-size: 18px;
    color: #AA232F;
  }
  > div {
    font: 400 1.7rem Roboto;
    line-height: 3rem;
    margin-top: 20px;
  }

`;

export const EnderecoContato = styled.div`
  width: 40%;
  text-align: center;

  @media (min-width: 1000px) {
    text-align: left;
  }
  > div {
    font: 400 1.7rem Roboto;
    line-height: 3rem;
    margin-top: 20px;
    margin-bottom: 40px;
  }
  > strong {
    font-size: 18px;
    color: #AA232F;
  }
`;

export const FormularioContato = styled.div`
  margin-top: 20px;
  > input {
    background-color: rgba(247, 247, 247, 1);
    -webkit-appearance: none;
    -moz-appearance: none;
    border: 0px solid rgba(59, 58, 58, 1);
    color: #3B3A3A;
    margin: 0 0 5px;
    width: 100%;
    padding: 8px;
    outline: none;
  }

  > textarea {
    background-color: rgba(247, 247, 247, 1);
    -webkit-appearance: none;
    -moz-appearance: none;
    border: 0px solid rgba(59, 58, 58, 1);
    color: #3B3A3A;
    margin: 0 0 5px;
    width: 100%;
    padding: 8px;
    height: 120px;
    outline: none;
    resize: none;
  }
`;

export const FormButton = styled.button`
  border: none;
  background-color: rgba(30, 45, 59, 1);
  color: #fFF;
  padding: 10px 10px;
  float: right;
`;

export const Copyright = styled.div`
  width: 100%;
  background-color: rgba(256,256, 256, 0.5);
  margin-top: 100px;
  text-align: center;

  @media (min-width: 1000px) {
    text-align: left;
  }

  div{
    font: 400 14px Roboto;
    max-width: 1120px;  
    margin: 0px auto;
    letter-spacing:0.04em;
    color: #3B3A3A;
    position: relative;
    height: auto;

    @media (min-width: 1000px) {
      height: 55px;
    }

    div {
      &.copy {
        padding-top: 20px;
        margin-bottom: 30px;
        @media (min-width: 1000px) {
          height: 55px;
          margin-bottom: 0px;
        }

        a {
          color: #3B3A3A;
        }
      }
    }

    div {
      &.logoFooter {
        

        @media (min-width: 1000px) {
          position: absolute;
          right: 0;
          top: -25px;
          z-index: 9;

          img {
            margin-top: -20px;
          }
        }

      }
    }
    div div {
      @media (min-width: 1000px) {
        text-align: right;
      }
    }
  }
`;