import React from 'react';
import { Link } from 'react-router-dom';

import { LogoSite, TitlePageInterna, Container, TitlePage, ContentCenter, TitleSectionLeft, BorderLeft, ListaProduto, BtnHome, Copyright } from './styles';

import lataAcrilica from '../../../assests/images/lata-acrilica.webp';
import familiaPremiumTintas from '../../../assests/images/familia-premium_tintas.webp';
import massaTextura from '../../../assests/images/massa-textura.webp';
import logoSiteSecundario from '../../../assests/images/tintas_shows.webp';
import logoFooter from '../../../assests/images/madequimica.webp';

const PageProductImobiliario: React.FC = () => {
  return (
    <Container>
      <TitlePage>
        <ContentCenter>
          <div className="topoComLogo">
            <TitleSectionLeft>
              linha imobiliária
              <BorderLeft />
            </TitleSectionLeft>
            <LogoSite>
              <span>conheça nosso site:</span>
              <a href="http://tintasshow.com.br" target="_blank" rel="noopener noreferrer"><img src={logoSiteSecundario} alt=""/></a>
            </LogoSite>
          </div>
        </ContentCenter>
      </TitlePage>
      <ContentCenter>
        <p>
          Proporciona embelezamento, proteção, durabilidade e manutenção de superfícies de alvenaria, madeira, ferrosas e não ferrosas. Produtos com alto rendimento e tecnologia, atestando qualidade e resistência das aplicações. Atendendo Indústria da Construção Civil em geral, Empresas Prestadoras de Serviço de Pintura, Indústrias, Distribuidores e comércio em geral.
        </p>
        <TitlePageInterna>
          Produtos
        </TitlePageInterna>
      </ContentCenter>
      <ContentCenter>
        <ListaProduto>
          <div>
            <img src={lataAcrilica} alt=""/>
            <h3>FUNDO PREPARADOR</h3>
            <p>É desenvolvido especialmente para preparar superfícies porosas, de alta absorção, com alto poder de penetração, ótima aderência, fixando partículas soltas, uniformizando a absorção do substrato, para uso interno e externo.</p>
          </div>
          <div>
            <img src={lataAcrilica} alt=""/>
            <h3>SELADOR ACRILICO</h3>
            <p>Selador Acrílico uniformiza a absorção da tinta, alto poder de enchimento, fácil aplicação, acabamento fosco, economizando no consumo de tinta, para uso interno e externo.</p>
          </div>
          <div>
            <img src={lataAcrilica} alt=""/>
            <h3>STAIN IMPREGNANTE</h3>
            <p>Indicado para portas, portões, janelas, esquadrias, cercas, lambris, casas pré-fabricadas de madeira, móveis de madeira para jardins e madeiras decorativas em geral, fácil de aplicar, ação fungicida e inseticida, hidro-repelente, realça os veios da madeira, fácil manutenção e com triplo filtro solar e durabilidade de até 3 anos.</p>
          </div>
          <div>
            <img src={massaTextura} alt="" height="187"/>
            <h3>TEXTURAS E MASSAS</h3>
            <p>São um produto de primeira linha empregado para uniformizar e nivelar superfícies internas e externas, gesso, massa fina, fibrocimento, concreto, blocos de concreto, proporcionando um acabamento liso e sofisticado. É um produto de fácil aplicação, secagem rápida e excelente poder de enchimento, hidro-repelente, fácil de aplicar e lixar.</p>
          </div>
          <div>
            <img src={lataAcrilica} alt=""/>
            <h3>THINNERS, REDUTOR E DILUENTES</h3>
            <p>Produto Formulado com Solventes Aromáticos, Ésteres, Álcoois, Acetatos para obter uma evaporação balanceada. Seu uso é recomendado para diluição de esmaltes e primers sintéticos não emulsionados, além de limpeza em geral.</p>
          </div>
          <div>
            <img src={lataAcrilica} alt=""/>
            <h3>TINTA A ÓLEO</h3>
            <p>Tinta com acabamento a base de resinas alquídicas com excelente acabamento e secagem rápida. Indicado para pintura geral ou parcial de veículos automotivos, implementos agrícolas, máquinas industriais, madeiras e peças metálicas em geral previamente preparadas.</p>
          </div>  
          <div>
            <img src={familiaPremiumTintas} alt=""/>
            <h3>TINTA ACRÍLICA SUPER PREMIUM, PREMIUM, STANDARD E ECONÔMICA</h3>
            <p>É uma tinta de acabamento fosco, possui alta performance e rendimento para quem busca maior desempenho, durabilidade em pinturas de áreas externas e internas. É uma tinta de fácil aplicação, baixo respinga mento, ótima cobertura, excelente alastramento e resistência às intempéries e lavabilidade. E seu fino acabamento confere requinte e sofisticação aos ambientes. </p>
          </div>  
          <div>
            <img src={lataAcrilica} alt=""/>
            <h3>TINTA EMBORRACHADA</h3>
            <p>É uma tinta elástica que torna as paredes impermeáveis, protegendo-as contra infiltrações causadas por fissuras (trincas finas) de até livrando-as das ações indesejadas da chuva, do sereno, da maresia, da umidade do ar, do mofo e das algas. Além disto, seu acabamento fosco 100% acrílico oferece proteção contra a ação do sol, da poluição e demais intempéries.</p>
          </div>  
          <div>
            <img src={lataAcrilica} alt=""/>
            <h3>TINTA ESMALTE BASE AGUA E SOLVENTE</h3>
            <p>Esmalte Acetinado Premium Base Água é indicado para aplicação em superfícies externas e internas de madeiras, metais ferrosos, galvanizados, alumínio e PVC. É um produto a base de água, que proporciona ótima aplicação e fácil limpeza de equipamentos. Apresenta baixíssimo odor, não amarela e oferece ótima resistência a intempéries, além de possuir secagem rápida e proporcionar excelente acabamento.</p>
          </div>    
          <div>
            <img src={lataAcrilica} alt=""/>
            <h3>VERNIZ</h3>
            <p>Extra Esmalte Rápido Transparente é um produto de alta qualidade que deixa as superfícies protegidas e com acabamentos belíssimos, brilhantes. Sua fórmula inovadora apresenta excelente resistência às ações do tempo que protege madeiras por muito mais tempo. Possui excelente resistência a água e ótima ação fungicida, oferendo maior proteção às superfícies contra fungos. Indicado para superfícies de madeira e tijolos à vista em interiores e exteriores. </p>
          </div>  
          <div>
            <img src={lataAcrilica} alt=""/>
            <h3>TINTA PISO E QUADRAS</h3>
            {/* <p>Avenir Light é uma fonte limpa e elegante preferida pelos designers. É boa para os olhos e uma ótima fonte de títulos, parágrafos e mais.</p> */}
          </div>
        </ListaProduto>        
        <Link to="/">
          <BtnHome>Página Inicial</BtnHome>
        </Link>
        
      </ContentCenter>
      <Copyright>
        <div>
          <div className="copy">Copyright ©️ 2020 Madequímica Indústria de Tinta - Todos os direitos reservados | desenvolvido por <a href="https://api.whatsapp.com/send?phone=5548984858854&text=Ol%C3%A1%2C%20" rel="noopener noreferrer" target="_blank">Ad Marketing Digital</a>.</div>
          <div className="logoFooter">
            <div>Um produto:</div>
            <a target="_blank" href="http://madequimica.ind.br"><img src={logoFooter} alt="Madequimica"/></a>
          </div>
        </div>
      </Copyright>
    </Container>
    
  );
}

export default PageProductImobiliario;