import React, { useState } from 'react';
// import { Link, animateScroll as scroll } from 'react-scroll';

import { NavHashLink as NavLink } from 'react-router-hash-link';

import { HeaderSite, ContentHeader, MenuSite } from './styles';
import { BiMenuAltRight } from "react-icons/bi";

import logoSite from '../../assests/images/logo.webp'


interface Props {
  colorHeader: boolean;
}

const Header: React.FC<Props> = ({colorHeader}) => {

  const [menuOpen, setMenuOpen] = useState(false);

  function handlerMenuOpen(){
    setMenuOpen(!menuOpen);
  }

  function handleSetClodeMenu(){
    console.log(window.innerWidth);
    if(window.innerWidth < 910){

      console.log('w');
      setMenuOpen(!menuOpen);
      console.log(menuOpen);
    }
  }

  function scrollWithOffset(el: any, offsetN: any) {
    const yCoordinate = el.getBoundingClientRect().top + window.pageYOffset;
    const yOffset = offsetN; 
    window.scrollTo({ top: yCoordinate + yOffset, behavior: 'smooth' }); 
  }

  return (
    <HeaderSite className={colorHeader ? 'bgWhite' : ''}>
      <ContentHeader >

        <NavLink
          smooth
          to="/"
          activeClassName="selected"
        >
          <img src={logoSite} className="logo" alt=""/>
        </NavLink>

        <BiMenuAltRight size={50} color="#000" onClick={handlerMenuOpen} />
        <MenuSite className={menuOpen ? 'closeMenu' : ''}>
          <li>
            <NavLink
              smooth
              to="/"
              activeClassName="selected"
              onClick={handleSetClodeMenu}
            >
              HOME
            </NavLink>
          </li>
          <li>
            <NavLink
              smooth
              to="/#linha"
              scroll={el => scrollWithOffset(el, -150)}
              onClick={handleSetClodeMenu}
            >
              LINHAS
            </NavLink>
          </li>
          <li>
            <NavLink
              smooth
              to="/#quemsomos"
              scroll={el => scrollWithOffset(el, -120)}
              onClick={handleSetClodeMenu}
            >
              QUEM SOMOS
            </NavLink>
          </li>
          <li>
            <NavLink
              smooth
              to="/#contato"
              scroll={el => scrollWithOffset(el, -120)}
              onClick={handleSetClodeMenu}
            >
              CONTATO
            </NavLink>
          </li>


          {/* <li>
            <Link
              activeClass="active"
              to="section0"
              spy={true}
              smooth={true}
              offset={-70}
              duration={500}
            >
              HOME
            </Link>
          </li>
          <li>
            <Link
              activeClass="active"
              to="section1"
              spy={true}
              smooth={true}
              offset={-100}
              duration={500}
            >
              LINHAS
            </Link>
          </li>
          <li>
            <Link
              activeClass="active"
              to="section2"
              spy={true}
              smooth={true}
              offset={-100}
              duration={500}
            >
              QUEM SOMOS
            </Link>
          </li>
          <li>
            <Link
              activeClass="active"
              to="section3"
              spy={true}
              smooth={true}
              offset={-100}
              duration={500}
            >
              CONTATO
            </Link>
          </li> */}
        </MenuSite>
      </ContentHeader>
    </HeaderSite>
  );
  }

export default Header;