import React from 'react';
import { Link } from 'react-router-dom';

import { LogoSite, TitlePageInterna, Container, TitlePage, ContentCenter, TitleSectionLeft, BorderLeft, ListaProduto, BtnHome, Copyright } from './styles';

import familiaMadevia from '../../../assests/images/familia-madevia.webp';
import logoSiteSecundario from '../../../assests/images/madevialogo_transparente.webp';
import logoFooter from '../../../assests/images/madequimica.webp';

const PageProductDemarcacao: React.FC = () => {
  return (
    <Container>
      <TitlePage>
        <ContentCenter>
          <div className="topoComLogo">
            <TitleSectionLeft>
              linha demarcação
              <BorderLeft />
            </TitleSectionLeft>
            <LogoSite>
              <span>conheça nosso site:</span>
              <a href="http://madevia.com.br" target="_blank" rel="noopener noreferrer"><img src={logoSiteSecundario} alt=""/></a>
            </LogoSite>
          </div>
        </ContentCenter>
      </TitlePage>
      <ContentCenter>
        <p>
        Produtos de alta tecnologia, ótimo rendimento, ancoragem, resistência, durabilidade, secagem rápida e facilidade de uso, indicada para pintura de rodovias, vias urbanas, piso de cimento, ciclovia, aeroportos e helipontos. Produtos com alto rendimento e tecnologia, atestando qualidade e resistência das aplicações. Atendendo Concessionárias, Empresas de Sinalização Viária, Prefeituras, Estacionamentos, Indústrias e Aplicadores, Distribuidores e comércio em geral.
        </p>
        <TitlePageInterna>
          Produtos
        </TitlePageInterna>
      </ContentCenter>
      <ContentCenter>
        <ListaProduto>
          <div>
            <img src={familiaMadevia} alt=""/>
            <h3>MICROESFERAS DE VIDRO</h3>
          </div>
          <div>
            <img src={familiaMadevia} alt=""/>
            <h3>THINNERS, REDUTOR E DILUENTES</h3>
          </div>
          <div>
            <img src={familiaMadevia} alt=""/>
            <h3>TINTAS EMARCAÇÃO BASE AGUA</h3>
          </div>
          <div>
            <img src={familiaMadevia} alt=""/>
            <h3>TINTAS DEMARCAÇÃO BASE SOLVENTE</h3>
          </div>
          <div>
            <img src={familiaMadevia} alt=""/>
            <h3>TINTA METILMETACRILATO MONOCOMPONENTE</h3>
          </div>
          <div>
            <img src={familiaMadevia} alt=""/>
            <h3>TINTA METILMETACRILATO BI-COMPONENTE</h3>
          </div>  
        </ListaProduto>        
        <Link to="/">
          <BtnHome>Página Inicial</BtnHome>
        </Link>
        
      </ContentCenter>
      <Copyright>
          <div>
            <div className="copy">Copyright ©️ 2020 Madequímica Indústria de Tinta - Todos os direitos reservados | desenvolvido por <a href="https://api.whatsapp.com/send?phone=5548984858854&text=Ol%C3%A1%2C%20" rel="noopener noreferrer" target="_blank">Ad Marketing Digital</a>.</div>
            <div className="logoFooter">
              <div>Um produto:</div>
              <a target="_blank" href="http://madequimica.ind.br"><img src={logoFooter} alt="Madequimica"/></a>
            </div>
          </div>
        </Copyright>
    </Container>
    
  );
}

export default PageProductDemarcacao;