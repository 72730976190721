import React from 'react';
import { Link } from 'react-router-dom';

import { TitlePageInterna, Container, TitlePage, ContentCenter, TitleSectionLeft, BorderLeft, ListaProduto, BtnHome, Copyright } from './styles';

import familiaMadequimica from '../../../assests/images/familia_1.webp';
import logoFooter from '../../../assests/images/madequimica.webp';

const PageProductMadeira: React.FC = () => {
  return (
    <Container>
      <TitlePage>
        <ContentCenter>
          <TitleSectionLeft>
            linha madeira
            <BorderLeft />
          </TitleSectionLeft>
        </ContentCenter>
      </TitlePage>
      <ContentCenter>
        <p>
        Proporciona embelezamento, proteção, durabilidade e manutenção de superfícies de madeira, como móveis, portas, janelas, decks, pisos, forros, etc. Produtos com alto rendimento e tecnologia, atestando qualidade e resistência das aplicações. Atendendo Indústria Moveleira em geral, Indústria de Painéis Compensados, Indústria de Portas, Marcenarias, Distribuidores e comércio em geral.
        </p>
        <TitlePageInterna>
          Produtos
        </TitlePageInterna>
      </ContentCenter>
      <ContentCenter>
        <ListaProduto>
          <div>
            <img src={familiaMadequimica} alt=""/>
            <h3>CERAS</h3>
          </div>
          <div>
            <img src={familiaMadequimica} alt=""/>
            <h3>TINTAS ESMALTE</h3>
          </div>
          <div>
            <img src={familiaMadequimica} alt=""/>
            <h3>MASSA PARA REPAROS </h3>
            <p>BRANCO E CORES</p>
          </div>
          <div>
            <img src={familiaMadequimica} alt=""/>
            <h3>PRIMER PU</h3>
            <p>BRANCO E CORES</p>
          </div>
          <div>
            <img src={familiaMadequimica} alt=""/>
            <h3>SELADOR PU</h3>
            <p> INCOLOR E TINGIDO</p>
          </div>
          <div>
            <img src={familiaMadequimica} alt=""/>
            <h3>STAIN IMPREGNANTE CORES</h3>
          </div>
          <div>
            <img src={familiaMadequimica} alt=""/>
            <h3>THINNERS, REDUTOR E DILUENTES</h3>
          </div>  
          <div>
            <img src={familiaMadequimica} alt=""/>
            <h3>TINGIDORES</h3>
          </div>  
          <div>
            <img src={familiaMadequimica} alt=""/>
            <h3>TINTA NITROCELULOSE</h3>
            <p>BRANCA E CORES</p>
          </div>  
          <div>
            <img src={familiaMadequimica} alt=""/>
            <h3>TINTA IMPERMEÁVEL PARA BORDA DE COMPENSADO</h3>
            <p>ACABAMENTO FOSCO AO BRILHANTE</p>
          </div>  
          <div>
            <img src={familiaMadequimica} alt=""/>
            <h3>VERNIZ PU</h3>
            <p>ACABAMENTO FOSCO AO BRILHANTE</p>
          </div>  
          <div>
            <img src={familiaMadequimica} alt=""/>
            <h3>TINTA PU</h3>
            <p>BRANCA E CORES<br /> ACABAMENTO FOSCO AO BRILHANTE</p>
          </div>  
          <div>
            <img src={familiaMadequimica} alt=""/>
            <h3>VERNIZ SINTETICO</h3>
            <p>ACABAMENTO FOSCO AO BRILHANTE</p>
          </div> 
        </ListaProduto>        
        <Link to="/">
          <BtnHome>Página Inicial</BtnHome>
        </Link>
        
      </ContentCenter>
      <Copyright>
        <div>
          <div className="copy">Copyright ©️ 2020 Madequímica Indústria de Tinta - Todos os direitos reservados | desenvolvido por <a href="https://api.whatsapp.com/send?phone=5548984858854&text=Ol%C3%A1%2C%20" rel="noopener noreferrer" target="_blank">Ad Marketing Digital</a>.</div>
          <div className="logoFooter">
            <div>Um produto:</div>
            <a target="_blank" href="http://madequimica.ind.br"><img src={logoFooter} alt="Madequimica"/></a>
          </div>
        </div>
      </Copyright>
    </Container>
    
  );
}

export default PageProductMadeira;