import styled from 'styled-components';

export const HeaderSite = styled.header`
  position: fixed;
  z-index: 4;
  top: 0;
  width: 100%;
  height: 93px;
  background-color: rgba(198, 198, 198, 0.83);
  /* background-color: rgba(256, 256, 256, 1); */
  border-top: 0px solid transparent;
  border-bottom: 0px solid transparent;
  box-shadow: 0 0 5px rgba(0, 0, 0, 0.5);
  transition: all ease 0.2s;

  &.bgWhite {
    background-color: #FFFFFF;

    > div ul li a {
      color: #000000!important;

      &:hover {
        color: #EDCD1F!important;
      }
    }
  }

  svg {
    position: absolute;
    right: 30px;
    cursor: pointer;
  }

  @media (min-width: 450px) {
    svg {
      right: 50px;
    }
  }

  @media (min-width: 910px) {
    svg {
      display: none;
    }
  }
`;

export const ContentHeader = styled.div`
  display: flex;

  > a img {
   width: 260px;
  }

  justify-content: space-between;
  align-items: center;
  height: 100%;

  @media (min-width: 910px) {
    max-width: 1120px;
    margin: 0 auto;
    height: 100%;

    > a img {
      width: 328px;
    }
  }
`;


export const MenuSite = styled.ul`
  list-style: none;
  font: 400 1.5rem Roboto;
  position: absolute;
  top: 94px;
  right: 0;
  padding-bottom: 20px;
  

  > li {
    padding: 0 40px;
    cursor: pointer;
    color: var(--color-text-in-primary);
    margin-top: 10px;

    a {
      color: #000000;
      transition: all ease .2s;
      text-decoration: none;
      
      &:hover {
        color: #ffffff!important;
      }
    }

  }

  &.closeMenu{
    display: flex;
  }

  background-color: #FFF;
  flex-direction: column;
  width: 200px;
  text-align: center;
  box-shadow: 0 0 5px rgba(0, 0, 0, 0.5);
  display:none;
  transition: all ease .2s;


  @media (min-width: 910px) {
     background-color: transparent;
     flex-direction: row;
     width: auto;
     text-align: left;
     position: inherit;
     box-shadow: none;
     display: flex!important;
     padding-bottom: 0px;

     > li {
      margin-top: 0px;
    }
  }
  
`;

