import React from 'react';
import { Link } from 'react-router-dom';

import { TitlePageInterna, Container, TitlePage, ContentCenter, TitleSectionLeft, BorderLeft, ListaProduto, BtnHome, Copyright } from './styles';

import familiaMadequimica from '../../../assests/images/familia_1.webp';
import logoFooter from '../../../assests/images/madequimica.webp';

const PageProductMaritimo: React.FC = () => {
  return (
    <Container>
      <TitlePage>
        <ContentCenter>
          <TitleSectionLeft>
            linha marítima
            <BorderLeft />
          </TitleSectionLeft>
        </ContentCenter>
      </TitlePage>
      <ContentCenter>
        <p>
        Produtos de alto desempenho, proporciona embelezamento, proteção, durabilidade e manutenção de superfícies de madeira, metal, plástico e fibra de vidro. Produtos com alto rendimento e tecnologia, atestando qualidade e resistência das aplicações. Atendendo Indústria Naval, Estaleiros, Portos, Container, Indústria de Pesqueira, Distribuidores e comércio em geral.
        </p>
        <TitlePageInterna>
          Produtos
        </TitlePageInterna>
      </ContentCenter>
      <ContentCenter>
        <ListaProduto>
          <div>
            <img src={familiaMadequimica} alt=""/>
            <h3>MASSA PARA REPAROS</h3>
          </div>
          <div>
            <img src={familiaMadequimica} alt=""/>
            <h3>PRIMERS ANTICORROSIVOS</h3>
          </div>
          <div>
            <img src={familiaMadequimica} alt=""/>
            <h3>PROMOTORES DE ADERÊNCIA</h3>
            <p>PARA DIVERSAS SUPERFÍCIES</p>
          </div>
          <div>
            <img src={familiaMadequimica} alt=""/>
            <h3>THINNERS, REDUTOR E DILUENTES</h3>
          </div>
          <div>
            <img src={familiaMadequimica} alt=""/>
            <h3>TINTA ANTI-INCRUSTANTE</h3>
          </div>
          <div>
            <img src={familiaMadequimica} alt=""/>
            <h3>TINTA ANTICORROSIVA</h3>
          </div>
        </ListaProduto>        
        <Link to="/">
          <BtnHome>Página Inicial</BtnHome>
        </Link>
        
      </ContentCenter>
      <Copyright>
        <div>
          <div className="copy">Copyright ©️ 2020 Madequímica Indústria de Tinta - Todos os direitos reservados | desenvolvido por <a href="https://api.whatsapp.com/send?phone=5548984858854&text=Ol%C3%A1%2C%20" rel="noopener noreferrer" target="_blank">Ad Marketing Digital</a>.</div>
          <div className="logoFooter">
            <div>Um produto:</div>
            <a target="_blank" href="http://madequimica.ind.br"><img src={logoFooter} alt="Madequimica"/></a>
          </div>
        </div>
      </Copyright>
    </Container>
    
  );
}

export default PageProductMaritimo;