import styled from 'styled-components';

export const Container = styled.div`
  padding-top: 95px;
`;

export const TitlePage = styled.div`
  height: auto;
  background: #F7F7F7;
  color: #C6C6C6;
  display: flex;
  align-items: flex-end;
  margin-bottom: 60px;

  @media (min-width: 900px) {
    height: 190px;
  }
  
`;

export const ContentCenter = styled.div`
  width: 100%;
  padding: 0 30px;
  margin: 0 auto;

  > div{
    &.topoComLogo {     

      display: flex;
      flex-direction: column;

      @media (min-width: 900px) {
        justify-content: space-between;
        flex-direction: row;
        align-items: flex-end;
      }

    }
  }

  @media (min-width: 900px) {
    width: 900px;
    padding: 0;
  }
  

  p {
    letter-spacing: 0.02em;
    line-height: 1.8;
    font-family: 'Helvetica';
    color: #3B3A3A;
    font-size: 14px;
    text-align: justify;
  }
`;

export const TitleSectionLeft = styled.h1`
  color: #C6C6C6;
  font: 700 5.5rem Roboto;
  letter-spacing: 0.05em;
  text-align: center;
  margin-bottom: 10px;

  @media (min-width: 900px) {
    text-align: left;
  }
`; 

export const BorderLeft = styled.div`
  border-top: 10px solid rgba(226, 28, 33, 1);
  width: 80px;
  height: 10px;
  margin-top: 8px;
  margin: 0 auto;

  @media (min-width: 900px) {
    margin: auto 0;
  }

`;

export const ListaProduto = styled.div`
  display: flex;
  justify-content: space-around;
  margin: 80px 0 50px 0;
  flex-wrap: wrap;

   div {
    padding: 0 25px; 
    margin-bottom: 50px;
    width: 100%;
    text-align: center;

    @media (min-width: 659px) {
      width: 50%;
    }
    @media (min-width: 900px) {
      width: 33%;
    }

    p {
      letter-spacing: 0.02em;
      line-height: 1.8;
      font-family: 'Helvetica';
      color: #3B3A3A;
      font-size: 14px;
      text-align: justify;
    }
  }

  h3 {
    color: #AA232F;
    font-weight: bold;
    font-size: 22px;
    text-align: center;
    margin-bottom: 8px;
    margin-top: 20px;
  }

  @media (min-width: 659px) {
    div + div {
      border-left: 1px solid #C6C6C6;
    }
    div:nth-child(2n+1) {
      border: none;
    }
  }

  @media (min-width: 900px) {
    div, div:nth-child(2n+1) {
      border-left: 1px solid #C6C6C6;
    }
    div:first-child, div:nth-child(4) { 
      border-left: none;
    }
  }


`;

export const BtnHome = styled.div`
  width: 170px;
  background-color: #3B3A3A;
  color: #fff;
  text-align: center;
  padding: 10px 0;
  margin: 0 auto 50px auto;
  transition: all ease .2s;
  font-size: 16px;

  a {
    text-decoration: none;
  }

  &:hover {
    background-color: rgba(237, 205, 31, 1);
    color: #3B3A3A;
  }
`;

export const Copyright = styled.div`
  width: 100%;
  background-color: rgba(256,256, 256, 0.5);
  margin-top: 100px;
  text-align: center;

  @media (min-width: 1000px) {
    text-align: left;
  }

  div{
    font: 400 14px Roboto;
    max-width: 1120px;  
    margin: 0px auto;
    letter-spacing:0.04em;
    color: #3B3A3A;
    position: relative;
    height: auto;

    @media (min-width: 1000px) {
      height: 55px;
    }

    div {
      &.copy {
        padding-top: 20px;
        margin-bottom: 30px;
        @media (min-width: 1000px) {
          height: 55px;
          margin-bottom: 0px;
        }

        a {
          color: #3B3A3A;
        }
      }
    }

    div {
      &.logoFooter {
        

        @media (min-width: 1000px) {
          position: absolute;
          right: 0;
          top: -25px;
          z-index: 9;

          img {
            margin-top: -20px;
          }
        }

      }
    }
    div div {
      @media (min-width: 1000px) {
        text-align: right;
      }
    }
  }
`;

export const TitlePageInterna = styled.div`
  width: 110px;
  border-bottom: 3px solid rgba(226, 28, 33, 1);
  font: 700 26px Roboto;
  color: rgb(198, 198, 198);
  margin-top: 30px;
`;

export const LogoSite = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 50px; 
  margin-bottom: 30px;

  @media (min-width: 900px) {
    margin-top: -50px;
    margin-bottom: 0px;
  }

  span {
    color: #000000;
  }
`;
