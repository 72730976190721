import React from 'react';
import { Switch, Route } from 'react-router-dom';

import PageHome from '../pages/PageHome';
import PageProductIndustrial from '../pages/PageProduct/PageProductIndustrial';
import PageProductImobiliaria from '../pages/PageProduct/PageProductImobiliario';
import PageProductDemarcacao from '../pages/PageProduct/PageProductDemarcacao';
import PageProductMadeira from '../pages/PageProduct/PageProductMadeira';
import PageProductMaritimo from '../pages/PageProduct/PageProductMaritimo';

const Routes: React.FC = () => (
  <Switch>
    <Route path='/' exact component={PageHome} />
    <Route path='/industrial' component={PageProductIndustrial} />
    <Route path='/imobiliaria' component={PageProductImobiliaria} />
    <Route path='/demarcacao' component={PageProductDemarcacao} />
    <Route path='/madeira' component={PageProductMadeira} />
    <Route path='/maritimo' component={PageProductMaritimo} />
  </Switch>
);

export default Routes;