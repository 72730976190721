import React, { useEffect } from 'react';
import { animateScroll as scrollHome } from 'react-scroll';
import { Link } from 'react-router-dom';

import {
  SpaceContent, 
  ContentFluid, 
  Content,  
  BannerSite, 
  LinhaItem, 
  TitleSection, 
  BorderTitle, 
  TitleSectionLeft,
  TextSection,
  SectionAbout, 
  TextAbout,
  TitleAbout,
  BackgroundAbout,
  BackgroundAboutFixed,
  FaixaAbout,
  SectionClient,
  ListClient,
  ItemClient,
  MapaContato,
  ContentContato,
  FormContato,
  EnderecoContato,
  FormularioContato,
  BorderLeft,
  FormButton,
  Copyright

} from './styles'

import imgBanner from '../../assests/images/banner_01.webp';
import linhaItemIndustrial from '../../assests/images/industrial.webp';
import linhaItemImobiliaria from '../../assests/images/imobiliaria.webp';
import linhaItemDemarcacao from '../../assests/images/estrada.webp';
import linhaItemMadeira from '../../assests/images/moveleira.webp';
import linhaItemMaritima from '../../assests/images/navio.webp';

import clientArteris from '../../assests/images/arteris.png';
import clientCorreia from '../../assests/images/correia.webp';
import clientUrupes from '../../assests/images/urupes.webp';
import clientSilkollor from '../../assests/images/silkollor.webp';
import clientSobercos from '../../assests/images/sobercos.webp';
import logoFooter from '../../assests/images/madequimica.webp';

const PageHome: React.FC = () => {

  useEffect(() => {
    scrollHome.scrollToTop();
  }, []);

  return (
    <>
    <BannerSite>
      <img src={imgBanner} alt=""/>
    </BannerSite>
    <SpaceContent >
      <ContentFluid>
        <Content>
          <LinhaItem>
            <li>
              <Link to="/industrial">
                <img src={linhaItemIndustrial} alt="Industrial"/>
                <strong>industrial</strong>
              </Link>
            </li>
            <li>
              <Link to="/imobiliaria">
                <img src={linhaItemImobiliaria} alt="Imóbiliaria"/>
                <strong>imóbiliaria</strong>
              </Link>
            </li>
            <li>
              <Link to="/demarcacao">
                <img src={linhaItemDemarcacao} alt="Demarcação"/>
                <strong>demarcação</strong>
              </Link>
            </li>
            <li>
              <Link to="/madeira">
                <img src={linhaItemMadeira} alt="Moveleira"/>
                <strong>madeira</strong>
              </Link>
            </li>
            <li>
              <Link to="/maritimo">
                <img src={linhaItemMaritima} alt="Marítima"/>
                <strong>marítima</strong>
              </Link>
            </li>
          </LinhaItem>
          <TitleSection id="linha">
            NOSSAS LINHAS
            <BorderTitle />
          </TitleSection>
          <TextSection>
            Dedicação e compromisso ao fabricar resultam na qualidade e excelência de cada produto Madequímica.
          </TextSection>
        </Content>
        <BackgroundAbout >
          <BackgroundAboutFixed >
            <SectionAbout>
              <TextAbout>
                <TitleSectionLeft id="quemsomos">
                  QUEM SOMOS
                  <BorderLeft />
                </TitleSectionLeft>
                <p>
                Desde 1988 trabalhando em prol da entrega de produtos inovadores, com máxima qualidade e excelente desempenho.
                <br />
                A Madequímica vai além da produção de tintas atentando-se às necessidades de cada cliente, evoluindo a cada colorir.
                
                <TitleAbout>MISSÃO</TitleAbout>

                Continuar transmitindo o sentimento das pessoas através das cores, com qualidade, além de sempre buscar
                embelezar e proteger superfícies.

                <TitleAbout>VALORES</TitleAbout>

                Ética Integridade - Responsabilidade - Qualidade dos produtos e serviços - Valorização das pessoas - Melhoria contínua e comprometimento com os resultados.

                <TitleAbout>VISÃO</TitleAbout> 

                Ser reconhecida como uma fabricante de tintas de qualidade catarinense em 2021.
                </p>
              </TextAbout>
            </SectionAbout>
            <FaixaAbout />
          </BackgroundAboutFixed>
        </BackgroundAbout>
        <SectionClient>
          <TitleSection>
            CLIENTES
            <BorderTitle />
          </TitleSection>
          <ListClient>
            <ItemClient>
              <a href="#" target="_blank" rel="noopener noreferrer">
                <img src={clientArteris} alt=""/>
              </a>   
            </ItemClient>
            <ItemClient>
              <a href="#" target="_blank" rel="noopener noreferrer">
                <img src={clientCorreia} alt="" width="147"/> 
              </a> 
            </ItemClient>
            <ItemClient>
              <a href="#" target="_blank" rel="noopener noreferrer">
                <img src={clientUrupes} alt=""/>  
              </a>
            </ItemClient>
            <ItemClient>
              <a href="#" target="_blank" rel="noopener noreferrer">
                <img src={clientSilkollor} alt=""/>  
              </a>
            </ItemClient>
            <ItemClient>
              <a href="#" target="_blank" rel="noopener noreferrer">
                <img src={clientSobercos} alt=""/> 
              </a>
            </ItemClient>
          </ListClient>
        </SectionClient>
        <TitleSection id="contato">
          CONTATO
          <BorderTitle />
        </TitleSection>
        <MapaContato>
          <div>
            <iframe title="Como chegar" src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d458357.54773951886!2d-49.65062897241621!3d-26.16678876223877!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x94ddd0f8f1c4c269%3A0xc09ebbe21d2a2b93!2sR.%20Augusto%20Gneiding%2C%20774%20-%20Industrial%20Norte%2C%20Rio%20Negrinho%20-%20SC%2C%2089295-000!5e0!3m2!1spt-BR!2sbr!4v1599086246136!5m2!1spt-BR!2sbr"></iframe>
          </div>
        </MapaContato>
        <ContentContato>
          <FormContato>
            <strong>Informações</strong>
            <div>
              Entre em contato e nos conte o que achou dos nossos produtos (47) 3644-5002.
            </div>
            <FormularioContato>
              <input type="text" id="field1" name="Name" value="" placeholder="Name *" data-aid="nameField" />
              <input type="text" id="field1" name="Name" value="" placeholder="E-mail *" data-aid="nameField" />
              <input type="text" id="field1" name="Name" value="" placeholder="Assunto *" data-aid="nameField" />
              <textarea placeholder="Menssagem"></textarea>
              <FormButton>Enviar</FormButton>
            </FormularioContato>
          </FormContato>
          <EnderecoContato>
          <strong>Fábrica</strong>
          <div>
            Rua Augusto Gneiding, 774<br />
            Rio Negrinho, 89295-000
            <br /><br />
            fabrica@madequimica.ind.br
          </div>
          <strong>
            Orçamento: (47) 3644-5002<br /><br />
            <BorderLeft />
          </strong>

          </EnderecoContato>
        </ContentContato>
        <Copyright>
          <div>
            <div className="copy">Copyright ©️ 2020 Madequímica Indústria de Tinta - Todos os direitos reservados | desenvolvido por <a href="https://api.whatsapp.com/send?phone=5548984858854&text=Ol%C3%A1%2C%20" rel="noopener noreferrer" target="_blank">Ad Marketing Digital</a>.</div>
            <div className="logoFooter">
              <div>Um produto:</div>
              <a target="_blank" href="http://madequimica.ind.br"><img src={logoFooter} alt="Madequimica"/></a>
            </div>
          </div>
        </Copyright>
      </ContentFluid>
    </SpaceContent>
  </>
  );
}

export default PageHome;