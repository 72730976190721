import React from 'react';
import { Link } from 'react-router-dom';

import { TitlePageInterna, Container, TitlePage, ContentCenter, TitleSectionLeft, BorderLeft, ListaProduto, BtnHome, Copyright } from './styles';

import familiaMadequimica from '../../../assests/images/familia_1.webp';
import unicaMadequimica from '../../../assests/images/madequimica_unica.webp';
import logoFooter from '../../../assests/images/madequimica.webp';

const PageProductIndustrial: React.FC = () => {
  return (
    <Container>
      <TitlePage>
        <ContentCenter>
          <TitleSectionLeft>
            linha industrial
            <BorderLeft />
          </TitleSectionLeft>
        </ContentCenter>
      </TitlePage>
      <ContentCenter>
        <p>
          Essa linha foca em produtos para embelezamento, proteção, durabilidade e manutenção de superfícies metálicas, ferrosas e não ferrosas, galvanizados, zincados, plásticos, fibra de vidro, pisos, containers e outras. Produtos com alto rendimento e tecnologia, atestando qualidade e resistência das aplicações.
          <br /><br />
          Atendendo Indústria de Máquinas e Implementos, Galpões, Laticínios, Bebidas, Serralheria, Saneamento, Usinagem, Ferramentas e Metal Mecânica em geral, bem como Distribuidores e comércio em geral, prestadores de serviço de jateamento e pintura.
        </p>
        <TitlePageInterna>
          Produtos
        </TitlePageInterna>
      </ContentCenter>
      <ContentCenter>
        <ListaProduto>
          <div>
            <img src={familiaMadequimica} alt=""/>
            <h3>CONVERTEDOR DE FERRUGEM</h3>
          </div>
          <div>
            <img src={familiaMadequimica} alt=""/>
            <h3>PRIMERS ANTICORROSIVOS E PROMOTORES DE ADERENCIA PARA DIVERSAS SUPERFÍCIES</h3>
          </div>
          <div>
            <img src={familiaMadequimica} alt=""/>
            <h3>SELADOR EPOXI</h3>
          </div>
          <div>
            <img src={unicaMadequimica} alt="" height="170"/>
            <h3>THINNERS, REDUTOR E DILUENTES</h3>
          </div>
          <div>
            <img src={familiaMadequimica} alt=""/>
            <h3>TINTA ACRILICA MONOCOMPONENTE</h3>
          </div>
          <div>
            <img src={familiaMadequimica} alt=""/>
            <h3>TINTA ALTA TEMPERATURA</h3>
          </div>  
          <div>
            <img src={familiaMadequimica} alt=""/>
            <h3>TINTA ESMALTE SINTETICO</h3>
          </div>  
          <div>
            <img src={familiaMadequimica} alt=""/>
            <h3>TINTA EPOXI</h3>
          </div>  
          <div>
            <img src={familiaMadequimica} alt=""/>
            <h3>TINTA NITRO-SINTETICA</h3>
          </div>  
          <div>
            <img src={familiaMadequimica} alt=""/>
            <h3>TINTA POLIURETANO BI-COMPONENTE</h3>
          </div>  
          <div>
            <img src={familiaMadequimica} alt=""/>
            <h3>TINTA VINILICA</h3>
          </div>  
        </ListaProduto>        
        <Link to="/">
          <BtnHome>Página Inicial</BtnHome>
        </Link>
        
      </ContentCenter>
      <Copyright>
        <div>
          <div className="copy">Copyright ©️ 2020 Madequímica Indústria de Tinta - Todos os direitos reservados | desenvolvido por <a href="https://api.whatsapp.com/send?phone=5548984858854&text=Ol%C3%A1%2C%20" rel="noopener noreferrer" target="_blank">Ad Marketing Digital</a>.</div>
          <div className="logoFooter">
            <div>Um produto:</div>
            <a target="_blank" href="http://madequimica.ind.br"><img src={logoFooter} alt="Madequimica"/></a>
          </div>
        </div>
      </Copyright>
    </Container>
    
  );
}

export default PageProductIndustrial;